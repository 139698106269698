import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
export default new Router({
  routes: [
    {
      path: "/",
      //redirect: "/dashboard",
      redirect: "/accueil",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          redirect: "/accueil"
        },
        /****My****/
        {
          path: "/accueil",
          name: "accueil",
          component: () => import("@/view/Accueil.vue"),
          meta: {
            rule: 'isUser'
          }
        },
        {
          path: "/users",
          name: "users",
          component: () => import("@/view/pages/vue-bootstrap/VueBootstrap.vue"),
          children:[
            {
              path: "liste",
              name: "users-liste",
              component: () => import("@/view/users/Liste.vue"),
              meta: {
                rule: 'isAdmin'
              }
            },
            {
              path: "nouveau",
              name: "users-ajouter",
              component: () => import("@/view/users/Ajouter.vue"),
              meta: {
                rule: 'isAdmin'
              }
            },
            {
              path: "detail/:id",
              name: "users-detail",
              component: () => import("@/view/users/Detail.vue"),
              meta: {
                rule: 'isAdmin'
              }
            }
          ]
        },
        {
          path: "/client",
          name: "client",
          component: () => import("@/view/pages/vue-bootstrap/VueBootstrap.vue"),
          children:[
            {
              path: "liste",
              name: "client-liste",
              component: () => import("@/view/client/Liste.vue"),
              meta: {
                rule: 'isAdmin'
              }
            },
            {
              path: "detail/",
              name: "client-detail",
              component: () => import("@/view/client/Detail.vue"),
              meta: {
                rule: 'isAdmin'
              }
            }
          ]
        },
        {
          path: "/banque",
          name: "banque",
          component: () => import("@/view/pages/vue-bootstrap/VueBootstrap.vue"),
          children:[
            {
              path: "liste",
              name: "banque-liste",
              component: () => import("@/view/banque/Liste.vue"),
              meta: {
                rule: 'admin'
              }
            },
            {
              path: "details/:id",
              name: "banque-details",
              component: () => import("@/view/banque/Details.vue"),
              meta: {
                rule: 'admin'
              }
            }
          ]
        },
      ]
    },
    {
      path: "/error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue")
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/view/pages/error/Error-2.vue")
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/view/pages/error/Error-3.vue")
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/view/pages/error/Error-4.vue")
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/view/pages/error/Error-5.vue")
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue")
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/Auth"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/Login"),
          meta: {
            rule: 'public'
          }
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/Register")
        }
      ]
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue"),
      meta: {
        rule: 'public'
      }
    }
  ]
});
